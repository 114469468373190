<template>
    <section id="dashboard-ecommerce" style="max-width: 100rem; margin: auto;">
        <b-row class="match-height">
            <b-col cols="4">

            </b-col>
            <b-col cols="8">
                <b-card>
                    <b-card-header>
                        <b-card-title>
                            Statistics
                        </b-card-title>
                        <span>
                            <b-avatar size="45">
                                <feather-icon
                                    size="21"
                                    icon="UserIcon"
                                />
                            </b-avatar>
                        </span>
                    </b-card-header>
                    <b-card-body>
                        <b-card>
                            <b-row>
                                <b-col cols="3">
                                    <b-card-body class="d-flex justify-content-between align-items-center">
                                        <b-avatar
                                            variant="light-success"
                                            size="45"
                                        >
                                            <feather-icon
                                                size="21"
                                                icon="UserIcon"
                                            />
                                        </b-avatar>
                                        <div class="truncate">
                                            <h2 class="mb-25 font-weight-bolder">
                                                Users
                                            </h2>
                                            <span>250</span>
                                        </div>
                                    </b-card-body>
                                </b-col>
                                <b-col cols="3">
                                    <b-card-body class="d-flex justify-content-between align-items-center">
                                        <b-avatar
                                            variant="light-success"
                                            size="45"
                                        >
                                            <feather-icon
                                                size="21"
                                                icon="UserIcon"
                                            />
                                        </b-avatar>
                                        <div class="truncate">
                                            <h2 class="mb-25 font-weight-bolder">
                                                Users
                                            </h2>
                                            <span>250</span>
                                        </div>
                                    </b-card-body>
                                </b-col>
                                <b-col cols="3">
                                    <b-card-body class="d-flex justify-content-between align-items-center">
                                        <b-avatar
                                            variant="light-success"
                                            size="45"
                                        >
                                            <feather-icon
                                                size="21"
                                                icon="UserIcon"
                                            />
                                        </b-avatar>
                                        <div class="truncate">
                                            <h2 class="mb-25 font-weight-bolder">
                                                Users
                                            </h2>
                                            <span>250</span>
                                        </div>
                                    </b-card-body>
                                </b-col>
                                <b-col cols="3">
                                    <b-card-body class="d-flex justify-content-between align-items-center">
                                        <b-avatar
                                            variant="light-success"
                                            size="45"
                                        >
                                            <feather-icon
                                                size="21"
                                                icon="UserIcon"
                                            />
                                        </b-avatar>
                                        <div class="truncate">
                                            <h2 class="mb-25 font-weight-bolder">
                                                Users
                                            </h2>
                                            <span>250</span>
                                        </div>
                                    </b-card-body>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </section>
</template>

<script>
    import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BAvatar, BLink, BButton } from 'bootstrap-vue'
    export default {
        components: {
            BRow,
            BCol,
            BCard, BCardHeader, BCardTitle, BCardBody,
            BAvatar, BLink, BButton
        },
        data(){

        }
    }
</script>
